<template>
    <b-card>
        <b-card-title
            class="mb-5"
            title-tag="h1"
        >
            {{ $t('auth.password.reset.title') }}
        </b-card-title>

        <b-form
            v-if="! response"
            @submit.prevent="onSubmit"
        >
            <b-form-group
                :invalid-feedback="form.errors.get('email')"
                :label="$t('inputs.email.label')"
                :state="! form.errors.has('email')"
            >
                <b-form-input
                    v-model="form.data.email"
                    :placeholder="$t('auth.inputs.email.placeholder')"
                    type="email"
                />
            </b-form-group>

            <b-form-group
                :invalid-feedback="form.errors.get('password')"
                :label="$t('inputs.newPassword.label')"
                :state="! form.errors.has('password')"
            >
                <b-form-input
                    v-model="form.data.password"
                    :placeholder="$t('auth.inputs.newPassword.placeholder')"
                    type="password"
                />
            </b-form-group>

            <btn-request
                block
                class="mt-5"
                :disabled="! form.data.token"
                :request="form"
                type="submit"
                variant="primary"
            >
                {{ $t('auth.password.reset.actions.request') }}
            </btn-request>
        </b-form>

        <template v-else>
            <template v-if="response.user.canLoginWeb">
                {{ $t('auth.password.reset.success.canLoginWeb') }}

                <b-button
                    v-t="'auth.password.reset.actions.home'"
                    block
                    class="mt-5"
                    variant="primary"
                    @click="redirectAuthenticated"
                />
            </template>

            <template v-else>
                {{ $t('auth.password.reset.success.canNotLoginWeb') }}
            </template>
        </template>
    </b-card>
</template>

<script>
import {mapActions} from 'vuex';
import Form from '@/library/http/Form';
import RecordsErrorMessage from '@/components/common/mixins/RecordsErrorMessage';
import RedirectsAuthenticated from '@/components/auth/mixins/RedirectsAuthenticated';

export default {
    name: 'Reset',
    mixins: [RecordsErrorMessage, RedirectsAuthenticated],
    data() {
        return {
            form: new Form({
                email: '',
                password: '',
                token: '',
            }),
            response: undefined,
        };
    },
    created() {
        this.form.data.email = this.$route.query.email;

        this.form.data.token = this.$route.query.token;

        if (!this.form.data.token) {
            this.form.errors.record([
                this.$t('auth.password.reset.validation.noToken'),
            ], 'email');
        }
    },
    methods: {
        ...mapActions('auth', [
            'login',
        ]),

        async onSubmit() {
            // Do not allow without token.
            if (!this.form.data.token) return;

            try {
                this.response = await this.form.post('/auth/password/reset');

                if (this.response.user.canLoginWeb) {
                    this.login(this.response);
                }
            } catch (e) {
                this.recordErrorMessage(e, 'email');

                return;
            }
        },
    },
};
</script>
