import Request from '@/library/http/Request';

export default class Form extends Request {
    /**
     * Form constructor.
     *
     * @param {object} data
     */
    constructor(data = {}) {
        super();

        this.data = data;
    }

    /**
     * Send a POST request to the given URL.
     */
    post(url) {
        return this.submit({
            url,
            method: 'post',
            data: this.data,
        });
    }

    /**
     * Send a PUT request to the given URL.
     *
     * @param {string} url
     */
    put(url) {
        return this.submit({
            url,
            method: 'put',
            data: this.data,
        });
    }

    /**
     * Send a PATCH request to the given URL.
     *
     * @param {string} url
     */
    patch(url) {
        return this.submit({
            url,
            method: 'patch',
            data: this.data,
        });
    }
}
