export default {
    methods: {
        recordErrorMessage(e, field) {
            if (e.status) {
                const message = e.data.message || `${e.status}: ${e.statusText}`;

                this.form.errors.record([message], field);
            }
        },
    },
};
